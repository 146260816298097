import { defineInlineAdSlotSchema } from "scmp-app/components/schema-render/common/inline-ad-slot/schemas";
import { defineTrackViewedPercentageSchema } from "scmp-app/components/schema-render/common/track-viewed-percentage/schemas";

export const DefaultCustomContents = [
  defineTrackViewedPercentageSchema({
    percentage: "0",
    row: 0,
  }),
  defineInlineAdSlotSchema({ row: 2, tag: "0" }),
  defineTrackViewedPercentageSchema({
    percentage: "20",
    row: 5,
  }),
  defineInlineAdSlotSchema({ row: 4, tag: "1" }),
  { row: 10, type: "inline-widget" },
  defineTrackViewedPercentageSchema({
    percentage: "50",
    row: 11,
  }),
  defineInlineAdSlotSchema({ row: 6, tag: "2" }),
  defineInlineAdSlotSchema({ row: 9, tag: "3" }),
  defineInlineAdSlotSchema({ row: 12, tag: "4" }),
  defineInlineAdSlotSchema({ row: 15, tag: "5" }),
  defineTrackViewedPercentageSchema({
    percentage: "100",
    row: -1,
  }),
  defineInlineAdSlotSchema({ row: 18, tag: "6" }),
  defineInlineAdSlotSchema({ row: -1, tag: "7" }),
];

export const PrintCustomContents = [
  defineTrackViewedPercentageSchema({
    percentage: "0",
    row: 0,
  }),
  defineTrackViewedPercentageSchema({
    percentage: "20",
    row: 5,
  }),
  defineTrackViewedPercentageSchema({
    percentage: "50",
    row: 11,
  }),
  defineTrackViewedPercentageSchema({
    percentage: "100",
    row: -1,
  }),
];
