import styled from "@emotion/styled";
import { fontBodoni, fontIndivisibleVariable, fontRoboto } from "@product/scmp-sdk";

export const Weekday = styled.div`
  grid-area: weekday;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-block: 0;
  padding-inline: 16px;
  border-block-end: 1px solid #777777;
  ${props => props.theme.breakpoints.up("tablet")} {
    border-block-end: none;
    border-inline-end: 1px solid #777777;

    flex-direction: column-reverse;

    padding-block: 16px;
    padding-inline: 0;
  }
`;

export const RotateText = styled.div`
  color: #111111;
  font-weight: 500;
  font-size: 12px;
  font-family: ${fontIndivisibleVariable};
  font-style: normal;
  letter-spacing: -0.24px;
  text-transform: uppercase;

  font-variation-settings: "wght" 500;
  ${props => props.theme.breakpoints.up("tablet")} {
    transform: scale(-1, -1);
    writing-mode: vertical-lr;
  }
`;

export const TitleWrapper = styled.div`
  grid-area: title;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-block: 0;
  padding-inline: 40px;
`;

export const Heading = styled.div`
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontIndivisibleVariable};
  letter-spacing: -0.24px;
  font-variation-settings: "wght" 700;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 16px;
    letter-spacing: -0.32px;
  }
`;

export const SubheadingWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;

  margin-block-start: 8px;
  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
  }
`;

export const baseSubheading = styled.div`
  color: #111111;
  font-size: 40px;
  font-family: ${fontBodoni};
  line-height: 100%;
  text-transform: capitalize;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 48px;
    line-height: 110%;
  }
`;

export const LiveText = styled(baseSubheading)`
  font-weight: 900;
  letter-spacing: -2px;
  ${props => props.theme.breakpoints.up("tablet")} {
    letter-spacing: -2.4px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-weight: 700;
  }
`;

export const CultureText = styled(baseSubheading)`
  font-weight: 400;
  letter-spacing: -2px;
  ${props => props.theme.breakpoints.up("tablet")} {
    letter-spacing: -2.4px;
  }
`;

export const DiscoveryText = styled(baseSubheading)`
  font-weight: 400;
  letter-spacing: -2.8px;
  ${props => props.theme.breakpoints.up("tablet")} {
    letter-spacing: -3.36px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    letter-spacing: -2.4px;
  }
`;

export const ShortDescription = styled.div`
  margin-block-start: 16px;

  font-size: 16px;
  font-family: ${fontIndivisibleVariable};
  letter-spacing: -0.32px;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 8px;

    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.4px;
    font-variation-settings: "wght" 400;

    text-transform: capitalize;
  }
`;

export const Divider = styled.div`
  grid-area: divider;

  display: none;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: flex;
    align-self: center;

    inline-size: 1px;
    block-size: 293px;
    border-inline-end: 1px solid #777777;
  }
`;

export const DescriptionWrapper = styled.div`
  grid-area: description;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-block: 0;
  padding-inline: 40px;
`;

export const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  font-family: ${fontIndivisibleVariable};
  line-height: 110%;
  letter-spacing: -0.32px;
  font-variation-settings: "wght" 500;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 20px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
  }
`;

export const SignUpButton = styled.a`
  margin-block-start: 20px;
  padding-block: 8px;
  padding-inline: 16px;

  color: #222222;
  font-size: 20px;
  font-family: ${fontIndivisibleVariable};
  letter-spacing: -0.4px;
  text-align: center;
  text-transform: capitalize;

  border: 1px solid #000000;

  cursor: pointer;
  font-variation-settings: "wght" 900;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
`;

export const TermAndConditionContainer = styled.div`
  margin-block-start: 40px;

  color: #777777;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
`;

export const TermAndConditionLink = styled.a`
  color: #777777;
`;

export const Container = styled.div`
  display: grid;
  grid:
    "weekday" 40px
    "." 40px
    "title" min-content
    "." 40px
    "description" min-content
    "." 40px
    / auto;
  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "weekday title divider description"
      / 40px 1fr 1px 1fr;

    block-size: 352px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    grid:
      "weekday title divider description"
      / 40px 1fr 1px 1fr;

    block-size: 352px;
  }
`;
