import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";

import { EntityOnelineMenu } from "scmp-app/components/entity-oneline-menu";
import { MenuItem, StyledSwiperSlide } from "scmp-app/components/entity-oneline-menu/styles";

import PostMagazineSvg from "./logo-post-magazine.svg";
import ScmpSvg from "./logo-scmp.svg";

export const Container = styled.div`
  display: grid;
  grid:
    "logo tag-line" min-content
    "sub-section sub-section" min-content / 1fr 140px;

  inline-size: 100%;
  padding-block-start: 32px;
  padding-inline: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "logo tag-line" min-content
      "sub-section sub-section" min-content / 1fr 160px;

    padding-block-start: 20px;
    padding-inline: 40px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    grid:
      "logo .tag-line" min-content
      "sub-section . tag-line" min-content / 1fr 32px 340px;

    padding-block-start: 40px;
  }
`;

export const LogoContainer = styled.div`
  grid-area: logo;

  display: flex;
  flex-direction: column;
`;

export const LogoScmpSvg = styled(ScmpSvg)`
  display: none;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;
  }
`;

export const LogoPostMagazine = styled(PostMagazineSvg)`
  inline-size: 200px;
  block-size: 48.35px;
  margin-block-start: 20px;
  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 300px;
    block-size: 72.52px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    inline-size: 400px;
    block-size: 98px;
  }
`;

export const StyledEntityOnelineMenu = styled(EntityOnelineMenu)`
  grid-area: sub-section;

  margin-block-start: 40px;
  ${MenuItem} {
    font-weight: 500;
    font-size: 13px;
    font-family: ${fontIndivisibleVariable};
    text-transform: uppercase;
    font-variation-settings: "wght" 500;

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 16px;
    }
  }

  ${StyledSwiperSlide} {
    &.swiper-slide {
      &:not(:last-child) {
        margin-inline-end: 13px;
        ${props => props.theme.breakpoints.up("tablet")} {
          margin-inline-end: 14px;
        }
        ${props => props.theme.breakpoints.up("homeDesktop")} {
          margin-inline-end: 28px;
        }
      }
    }
  }
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 32px;
  }

  ${MenuItem} {
    &[data-active="true"] {
      font-variation-settings: "wght" 700;
    }
  }
`;

export const TagLine = styled.div`
  grid-area: tag-line;

  display: flex;
  align-items: center;

  ${props => props.theme.breakpoints.up("tablet")} {
    align-items: stretch;
  }
`;

export const TagLineWrapper = styled.div`
  inline-size: 100%;
  padding-inline-start: 17px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline-start: 29px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    border-inline-start: 1px solid #777777;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-inline-start: 32px;
  }
`;
